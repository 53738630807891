import './App.css';
import React, { useState } from 'react'
import NavBar from './components/NavBar';
import News from './components/News';
import Footer from './components/Footer';

// React-Router-dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing Top loading Bar Installed using "npm i react-top-loading-bar" Website - "https://www.npmjs.com/package/react-top-loading-bar"
import LoadingBar from 'react-top-loading-bar'


function App() {
  const [mode, setmode] = useState('dark'); // Dark Mode 
  const toogleMode = () => {
    if (mode === 'light') {
      setmode('dark')
      document.body.style.backgroundColor = 'rgb(3 17 28)';
    }
    else {
      setmode('light')
      document.body.style.backgroundColor = 'rgb(233 236 239)';
    }
  }
  // Language
  const [language, setLanguage] = useState('en');
  const toggleLanguage = () => {
    if (language === 'hi') {
      setLanguage('en')
    }
    else {
      setLanguage('hi')
    }
  }

  // No Of articles to fetch 
  const max = 10;

  // API Key
  const apiKey = process.env.REACT_APP_GNEWS_API;

  // Country 
  const country = "in"


  // Top Loading Progress Bar 
  const [progress, setProgress] = useState(0);

  // Searching Function
  const [search, setSearch] = useState(null);
  const funSearch = (query) => {
    // Removing Extra Space after Search Term which lead to Page Not Load
    let term = query.trim();
    
    setSearch(term)
  }

  return (
    <>
      <div>
        <Router>
          <NavBar mode={mode} toogleMode={toogleMode} funSearch={funSearch} toggleLanguage={toggleLanguage} language={language} />
          <LoadingBar
            height={3}
            color='#f11946'
            progress={progress}
          />
          <Routes>
            {/* Used Exact Keyword to match exactly the same url, Use Key="" to auto-refresh component to with Category passed without page Refresh */}
            <Route exact path="/" element={
              <News setProgress={setProgress}
                key={"general" + language}
                title="Top Headlines"
                heading="Todays - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=breaking-news&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/business" element={
              <News setProgress={setProgress}
                key={"business" + language}
                title="Business"
                heading="Business - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=business&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/entertainment" element={
              <News setProgress={setProgress}
                key={"entertainment" + language}
                title="Entertainment"
                heading="Entertainment - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=entertainment&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/health" element={
              <News setProgress={setProgress}
                key={"health" + language}
                title="Health"
                heading="Health - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=health&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/science" element={
              <News setProgress={setProgress}
                key={"science" + language}
                title="Science"
                heading="Science - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=science&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/sports" element={
              <News setProgress={setProgress}
                key={"sports" + language}
                title="Sports"
                heading="Sports - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=sports&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/technology" element={
              <News setProgress={setProgress}
                key={"technology" + language}
                title="Technology"
                heading="Technology - Top Headlines"
                url={`https://gnews.io/api/v4/top-headlines?topic=technology&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path="/covid" element={
              <News setProgress={setProgress}
                key={"covid" + language}
                title="Covid-19"
                heading="Covid-19 - Top Headlines"
                url={`https://gnews.io/api/v4/search?q=covid&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

            <Route exact path={`/search`} element={
              <News setProgress={setProgress}
                key={search + language}
                title={search}
                heading={search + '- Top Results'}
                url={`https://gnews.io/api/v4/search?q=${search}&country=${country}&lang=${language}&sortby=publishedAt&max=${max}&token=${apiKey}`}
                mode={mode} toogleMode={toogleMode} toggleLanguage={toggleLanguage} language={language} />} />

          </Routes>
          <Footer mode={mode} toogleMode={toogleMode} />
        </Router>
      </div>
    </>
  )
}
export default App;

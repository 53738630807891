import React from 'react'

const NewsItem = (props) => {
    
    let {title, description, imageUrl, newsUrl, author, date, source} = props;
    return (
        <div className='my-3'>
            <div className={`card text-${props.mode==='light'?'dark':'light'} bg-${props.mode==='dark'?'dark':'light'}`}>
            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                {source}
            </span>
            {/* Added a Ternary Operator in Image when Image url is Null then It will show this mentioned Url */}
            <img src={imageUrl?imageUrl:"https://www.fayerwayer.com/resizer/p1dQlslwfC_sZBjndQ9JPfhcXk8=/1200x630/filters:format(jpg):quality(70)/cloudfront-us-east-1.images.arcpublishing.com/metroworldnews/CWMJW63E5FAZPCFRRJJDNXFSMU.jpg"} className="card-img-top" alt="..." />
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{description}</p>
                <p className="card-text"><small className="text-muted">By {author?author:"Unknown"} on {new Date(date).toLocaleString('hi-IN')}</small></p>
                <a href={newsUrl} target="blank" className="btn btn-sm btn-primary">Read More</a>
            </div>
            </div>
        </div>
    )
}

export default NewsItem

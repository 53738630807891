import React, { useEffect, useState } from 'react'
// Imported NewsItem Component inside this Component.
import NewsItem from './NewsItem'
// Imported Spinner.js Component inside this Component.
import Spinner from './Spinner';
import PropTypes from 'prop-types';

const News = (props) => {

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noArticles, setnoArticles] = useState('');
    const [api, setApi] = useState("")

    const updateNews = async () => {
        const url = `${props.url}`
        fetch(url)
            .then(async (response) => {
                if (response.ok) {
                    props.setProgress(10);
                    setLoading(true);
                    props.setProgress(30);
                    let parsedData = await response.json()
                    props.setProgress(70);
                    // console.log(parsedData);
                    setArticles(parsedData.articles)
                    if (articles.length === 0) {
                        setnoArticles("No Result Found")
                    }
                    setLoading(false)
                    props.setProgress(100);
                }
                else if (response.status === 403) {
                    return setApi("Today's API Limit Reached. Come Back Tomorrow.")
                }
            })
            .catch((error) => {
                console.log('Fetch Error :-S', error);
            })
    }

    // "UseEffect" is used as "ComponentDidMount" in Function Based Component
    // Using UseEffect from Snippet = "useeff"
    // Warning showing while Using "useEffect" to fix add one comment => // eslint-disable-next-line
    useEffect(() => {
        document.title = `${props.title} - HR-NewsApp`;
        updateNews();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {api !== "" ? <center> <h2 className='' style={{ marginTop:' 150px', height:'76.6vh', color: props.mode === 'dark' ? 'white' : '#042743'  }} >{api}</h2> </center>
                :
                <div className='container ' style={{ color: props.mode === 'dark' ? 'white' : '#042743' }}>
                    <h1 className='text-center text-capitalize' style={{ margin: '100px 0px 40px' }}>{props.heading}</h1>
                    {loading && <Spinner />}
                    <div className="container">
                        <div className="row mb-5">
                            {articles.length ? articles.map((element) => {
                                return <div className="col-md-4" key={element.url}>
                                    <NewsItem title={element.title ? element.title : ""} description={element.description ? element.description : ""} imageUrl={element.image} newsUrl={element.url} author={element.author} date={element.publishedAt} source={element.source.name} mode={props.mode} />
                                </div>
                            }) : <center> <h2 className='min-vh-100' >{noArticles}</h2> </center>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
News.defaultProps = {
    country: 'in',
}
News.propTypes = {
    country: PropTypes.string,
}
export default News

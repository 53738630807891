import React, { useEffect, useState, useRef } from 'react'
// Importing Link and useLocation from react-router-dom
// Imported "useNavigate" in previous version it is "useHistory" for navigation like link
import { Link, useLocation } from "react-router-dom";
const NavBar = (props) => {

    // Using Location Hook from React Router to Get Which Nav-Bar Title is Active and Highlight it
    let location = useLocation();
    useEffect(() => {
        // console.log(location.pathname);
    }, [location]);


    // Search Function
    const [text, setText] = useState({ search: "" });
    const onChange = (event) => {
        setText({ ...text, [event.target.name]: event.target.value });
    }

    // Using Navigate from react-router-dom for Search onClick button to redirect to "/search" like Link
    // const navigate = useNavigate();

    const onClick = () => {
        props.funSearch(text.search)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        autoCloseRef.current.click()
        // setText({search : ""})
        // navigate('/search')
        // autoCloseRef.current.click()
    }


    // Auto Closing the NavBar and Scrolling the New Page Top
    const autoCloseRef = useRef(null)
    const autoClose = () => {
        autoCloseRef.current.click()
        // For Auto Scroll Page to Top
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setText({search : ""})
    }

    // Scrolling Up page for New Search Term
    const scrollUp = () => {
        // For Auto Scroll Page to Top
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }


    return (
        <div>
            <nav className={`navbar fixed-top navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" onClick={autoClose}>HR-NewsApp</Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* offcanvas-end to Overlay from Right */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

                        <div className={`offcanvas-header text-${props.mode === "dark" ? "light" : "dark"} bg-${props.mode}`}>
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">HR-NewsApp</h5>

                            {/* Menu Close Button */}
                            <button ref={autoCloseRef} type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>

                        </div>

                        <div className={`offcanvas-body navbar-${props.mode} bg-${props.mode}`}>
                            {/* This Justify-Content-Center is For larger Screen */}
                            <ul className="navbar-nav align-items-center flex-grow-1 pe-3">

                                {/* This Justify-Content-Center is For Small Screen */}
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/" ? "active" : ""}`} to="/">Home</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/business" ? "active" : ""}`} to="/business">Business</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} to="/entertainment">Entertainment</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/health" ? "active" : ""}`} to="/health">Health</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/science" ? "active" : ""}`} to="/science">Science</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/sports" ? "active" : ""}`} to="/sports">Sports</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center nav-link ${location.pathname === "/technology" ? "active" : ""}`} to="/technology">Technology</Link>
                                </li>
                                <li className="nav-item" onClick={autoClose}>
                                    <Link className={`d-flex justify-content-center align-items-center nav-link ${location.pathname === "/covid" ? "active" : ""}`} to="/covid">Covid-19</Link>
                                </li>
                            </ul>

                            {/* SEARCH */}
                            <form className="d-flex align-items-center me-2">
                                <input className="form-control" value={text.search} id="search" name='search' onChange={onChange} type="search" placeholder="Search" aria-label="Search" />

                                <Link to="/search" onClick={scrollUp}><button disabled={text.search.length === 0} className={`btn btn-${props.mode === 'light' ? 'dark' : 'light'} ms-2`} onClick={onClick}> <i className="fa-solid fa-magnifying-glass"></i>
                                </button></Link>
                            </form>

                            {/* Dark MODE */}
                            <div className={`d-flex align-items-center justify-content-center m-2 form-check form-switch text-${props.mode === 'light' ? 'dark' : 'light'}`} onClick={autoClose}>
                                <input className="form-check-input" onClick={props.toogleMode} type="checkbox" role="switch" id="DarkMode" />

                                <label className="form-check-label px-2" htmlFor="DarkMode">Enable {props.mode === 'light' ? 'Dark' : 'Light'} Mode
                                </label>
                            </div>

                            {/* Language */}
                            <div className={`d-flex align-items-center justify-content-center m-2 form-check form-switch text-${props.mode === 'light' ? 'dark' : 'light'}`} onClick={autoClose}>
                                <input className="form-check-input" onClick={props.toggleLanguage} type="checkbox" role="switch" id="Language" />

                                <label className="form-check-label px-2" htmlFor="Language">Enable {props.language === 'hi' ? 'English' : 'Hindi'} Mode
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar
